import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";

export default (props) => (
  <StaticQuery
    query={graphql`
    query ImpactCards {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(impact-stories)/"}}, sort: {order: ASC, fields: frontmatter___order}) {
        nodes {
          frontmatter {
            path
            name
            quote
            personTitle
            thumbnailImage {
              publicURL
            }
          }
        }
      }
    }
    `}
    render={data => {
      
      const stories = data.allMarkdownRemark.nodes;

      var storylist = stories.map(function(story){

        if (props.path != story.frontmatter.path) {

          return (

            <div className="impact-story" style={{backgroundImage: `url(${story.frontmatter.thumbnailImage.publicURL})`}} key={story.frontmatter.path}>
              <div className="impact-story-content">
                <blockquote>
                  <p>{story.frontmatter.quote}</p>
                  <cite>{story.frontmatter.name}, {story.frontmatter.personTitle}</cite>
                </blockquote>
                <Link to={story.frontmatter.path} className="button pill orange">Read More</Link>
              </div>
            </div>

          )

        } else {
          return null;
        }
      })

      return storylist;
      
    }}
  />
)